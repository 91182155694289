<template>
    <div class="content">
        <div v-show="sliderShow" :class="moduleList.length == 3 ? 'bg-opacity-1' : 'bg-opacity-0'">
            <div id="index-view">
                <carousel-3d
                    ref="swiper"
                    width="600"
                    height="600"
                    border="0"
                    :space="500"
                    :inverseScaling="150"
                    :perspective="20"
                    :startIndex="0"
                    :onMainSlideClick="mainSlideClick"
                >
                    <slide :index="0">
                        <div class="inner">
                            <img src="../../../assets/img/index/index_img1.png" />
                        </div>
                    </slide>
                    <slide :index="1">
                        <div class="inner">
                            <img src="../../../assets/img/index/index_img2.png" />
                            <div v-if="lock" class="lock-t">
                                <div class="lock-img">
                                    <img src="../../../assets/img/index/lock.png"/></div
                            ></div>
                        </div>
                    </slide>
                    <slide :index="2">
                        <div class="inner">
                            <img src="../../../assets/img/index/index_img3.png" />
                            <div v-if="lock" class="lock-t">
                                <div class="lock-img">
                                    <img src="../../../assets/img/index/lock.png"/></div
                            ></div>
                        </div>
                    </slide>
                </carousel-3d>
            </div>
        </div>

        <div class="two-view" v-show="moduleList.length == 2">
            <div
                class="inner"
                @click="viewClick('1')"
                v-if="moduleList.findIndex((item) => item.name === '智能咨询系统') != -1"
            >
                <img src="../../../assets/img/index/index_img1.png" />
            </div>
            <div
                class="inner"
                @click="lock ? '' : viewClick('2')"
                v-if="moduleList.findIndex((item) => item.name === '文书生成系统') != -1"
            >
                <img src="../../../assets/img/index/index_img2.png" />
                <div v-if="lock" class="lock-t">
                    <div class="lock-img"> <img src="../../../assets/img/index/lock.png"/></div
                ></div>
            </div>
            <div
                class="inner"
                @click="lock ? '' : viewClick('3')"
                v-if="moduleList.findIndex((item) => item.name === '案件计算器') != -1"
            >
                <img src="../../../assets/img/index/index_img3.png" />
                <div v-if="lock" class="lock-t">
                    <div class="lock-img"> <img src="../../../assets/img/index/lock.png"/></div
                ></div>
            </div>
        </div>
        <div class="one-view" v-show="moduleList.length == 1">
            <div
                class="inner"
                @click="viewClick('1')"
                v-if="moduleList.findIndex((item) => item.name === '智能咨询系统') != -1"
            >
                <img src="../../../assets/img/index/index_img1.png" />
            </div>
            <div
                class="inner"
                @click="lock ? '' : viewClick('2')"
                v-if="moduleList.findIndex((item) => item.name === '文书生成系统') != -1"
            >
                <img src="../../../assets/img/index/index_img2.png" />
                <div v-if="lock" class="lock-t">
                    <div class="lock-img"> <img src="../../../assets/img/index/lock.png"/></div
                ></div>
            </div>
            <div
                class="inner"
                @click="lock ? '' : viewClick('2')"
                v-if="moduleList.findIndex((item) => item.name === '案件计算器') != -1"
            >
                <img src="../../../assets/img/index/index_img3.png" />
                <div v-if="lock" class="lock-t">
                    <div class="lock-img"> <img src="../../../assets/img/index/lock.png"/></div
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import * as Server from '@/api/server'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'Index',
        components: {
            Carousel3d,
            Slide,
        },
        data() {
            return {
                moduleList: [],
                sliderShow: true,
                lock: false,
            }
        },
        async beforeMount() {
            this.$store.dispatch('getHomePageTree')
        },

        async mounted() {
            let startX
            let endX
            let that = this
            let swiper = document.getElementById('index-view')

            swiper.addEventListener('touchmove', function(ev) {
                let touch = event.targetTouches[0]
                endX = touch.pageX
            })

            swiper.addEventListener('touchstart', function(ev) {
                let touch = event.targetTouches[0]
                startX = touch.pageX
            })

            swiper.addEventListener('touchend', function(ev) {
                let distanceX = endX - startX

                if (!endX) return

                if ((distanceX > 0 && distanceX < 50) || (distanceX < 0 && distanceX > -50)) return

                if (distanceX < 0) {
                    that.$refs.swiper.goNext()
                } else {
                    that.$refs.swiper.goPrev()
                }

                startX = endX = 0
            })
            await Server.Axios({
                method: 'GET',
                url: apiList.getHomePageTree,
                config: {
                    data: {},
                },
                loading: false,
            }).then((dataSource) => {
                this.moduleList = dataSource.data.returnData
                this.sliderShow = this.moduleList.length == 3
            })
            await Server.Axios({
                method: 'GET',
                url: apiList.serviceLimit,
                config: {
                    data: {},
                },
                loading: false,
            }).then((dataSource) => {
                console.log(dataSource, 'dataSource')
                if (dataSource.data.returnData == 3) {
                    this.lock = true
                }
            })
        },
        methods: {
            mainSlideClick(obj) {
                console.log(obj.index)
                if (this.lock && obj.index != 0) {
                    this.$message.warning(
                        '您的服务已到期，部分功能已受限，如有问题，请联系工作人员',
                        2
                    )
                } else {
                    this.$router.push({
                        name: 'ReasonList',
                        query: {
                            type: (obj.index + 1).toString(),
                        },
                    })
                }
            },

            viewClick(type) {
                this.$router.push({
                    name: 'ReasonList',
                    query: {
                        type: type,
                    },
                })
            },
        },
    }
</script>

<style lang="less" type="text/less">
    .content {
        width: 100vw;
        height: 100%;
        position: relative;
        overflow: hidden;
        #index-view {
            margin-top: 1.8rem;
            .carousel-3d-slide {
                background-color: rgba(0, 0, 0, 0);
            }

            .carousel-3d-slider,
            .carousel-3d-slide {
                width: 6rem !important;
                height: 6rem !important;
            }
        }
        .left-1 {
            transform: translateX(-5rem) translateZ(-2.5rem) rotateY(20deg) !important;
        }
        .right-1 {
            transform: translateX(5rem) translateZ(-2.5rem) rotateY(-20deg) !important;
        }

        .two-view {
            display: flex;
            justify-content: center;
            margin-top: 1.8rem;

            .inner {
                margin: 0 0.8rem;
            }
        }

        .one-view {
            display: flex;
            justify-content: center;
            margin-top: 1.8rem;
        }
        .bg-opacity-0 {
            opacity: 0;
        }
        .bg-opacity-1 {
            opacity: 1;
        }
    }
    .lock-t {
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
        width: 100%;
        height: 72%;
        width: 100%;
        top: 0;
    }
    .lock-img {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        z-index: 3;
    }
</style>
